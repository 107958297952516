import * as ReactDOMClient from 'react-dom/client';
import { Router } from './Router';
import { initialize } from 'react-ga';
import GTM from 'Utils/GTM';
import Hotjar from 'Utils/Hotjar';

if (process.env.NODE_ENV === 'production') {
  initialize(process.env.REACT_APP_GA_CODE || 'UA-97689914-279', {
    debug: true
  });
  new GTM('GTM-MXZKQV3Z');
  new Hotjar(3507987);
}

const root = ReactDOMClient.createRoot(document.getElementById('root') as HTMLElement);
root.render(<Router />);
