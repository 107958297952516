/* eslint-disable prefer-rest-params */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck

import { TUolHostUser } from 'Types/TUolHostUser';
import AxiosRequest from 'Utils/AxiosRequest';

export default class Hotjar {
  static isInited = false;

  constructor(code: number) {
    if (!Hotjar.isInited) {
      this.createScript(code);
      Hotjar.isInited = true;
    }
  }

  private async identifyUser() {
    try {
      const { data } = await AxiosRequest.get<TUolHostUser>(
        'https://platform-api.meupainelhost.uol.com.br/api/user'
      );

      const products = data.authorizedProducts.map((prod) => prod.name).toString();
      window.hj('identify', data.email, { products });

      window.uolHostVariables = {
        user: {
          ...data,
          authorizedProducts: products
        }
      };
    } catch (error) {
      console.error('Error on identify user', error);
    }
  }

  private createScript(code: number) {
    const script = (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: code, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
      return r;
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

    script.onload = () => {
      this.identifyUser();
    };
  }
}
